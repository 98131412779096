<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: 'app',
    }
</script>

<style>
    html, body {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
    }
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
        height: 100%;
    }
    .el-container {
        height: 100%;
    }
    .el-message {
        min-width: 100px !important;
    }
    /*.el-calendar-table:not(.is-range) td.next, .el-calendar-table:not(.is-range) td.prev{*/
    /*  pointer-events: none;*/
    /*}*/
    .todo{
        padding:30px;
        color: red;
    }
</style>
