<template>
    <zk-admin-table title="门诊导入" @search="handleSearch" :table="table" :loading="loading">
        <el-table stripe :data="table.data" style="width: 100%" v-loading="loading" border height="100%">
            <el-table-column type="index" :index="(e) => e + 1 + ((params.page -1) * params.pageSize)" align="center" width="50"/>
            <el-table-column align="center"
                             v-for="(v, idx) in table.columns"
                             :prop="v.field"
                             :label="v.title"
                             :width="v.width"
                             :formatter="v.formatter"
                             :filters="v.filters"
                             :key="idx">
            </el-table-column>
            <el-table-column width="120" align="center" label="操作" fixed="right">
                <template slot-scope="scope">
                    <el-button size="mini" @click="handleImport(scope.row)">导入</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog slot="dialog" title="患者信息" :visible.sync="dialogVisible" width="50%" center>
            <el-form :model="form" :rules="rules" ref="form" label-width="120px">
                <el-form-item label="姓名">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="身份证号">
                    <el-input v-model="form.idnum"></el-input>
                </el-form-item>
                <el-form-item label="就诊卡号">
                    <el-input v-model="form.hnum"></el-input>
                </el-form-item>
                <el-form-item label="联系电话">
                    <el-input v-model="form.phone"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="addSubmit">确 定</el-button>
            </span>
        </el-dialog>
    </zk-admin-table>
</template>

<script>
    export default {
        name: 'import_his',
        path: '/main/data/import/his',
        data() {
            return {
                form: {},
                rules: {},
                dialogVisible: false,
                loading: false,
                params: {
                    page: 1,
                    pageSize: 20,
                    doctor: []
                },
                table: {
                    buttons: [],
                    search: [
                        {
                            type: 'date',
                            key: 'date',
                            value: this.$route.query.date||'',
                            placeholder: '就诊日期'
                        },
                        {
                            type: 'input',
                            key: 'name',
                            placeholder: '患者姓名'
                        },
                    ],
                    columns: [
                        {title: '姓名', field: 'name', width: '200'},
                        {title: '身份证号', field: 'idnum', width: '200', formatter: null},
                        {title: '病卡号', field: 'hnum', width: '200', formatter: null},
                        {title: '联系电话', field: 'phone', width: '200', formatter: null},
                        {title: '就诊日期', field: 'date', width: ''},
                        {title: '就诊时间', field: 'time', width: ''},
                        {title: '就诊科室', field: 'department', width: ''},
                        {title: '接诊医生', field: 'expert', width: ''},
                    ],
                    data: [],
                    total: 0,
                    page: 1,
                    pageSize: 20
                },
            }
        },
        methods: {
            handleImport(row){
                this.form.name = row.name
                this.form.idnum = row.idnum
                this.form.hnum = row.hnum
                this.form.phone = row.phone
                this.dialogVisible = true
            },
            async addSubmit(){
                let resp = null
                if (this.form.id) {
                    resp = await this.$http.put(`/fengshi/patient/${this.form.id}`, this.form)
                } else {
                    resp = await this.$http.post('/fengshi/patient/', this.form)
                }
                if (resp.data.code == 200) {
                    this.$message.success("保存成功")
                    this.dialogVisible = false
                } else {
                    this.$message.error(resp.data.data)
                }
            },
            handleSearch(e) {
                this.params.page = e.page
                this.params.pageSize = e.pageSize
                this.getData(e)
            },
            getData(e) {
                this.loading = true
                setTimeout(() => {
                    this.$http.get(`/fengshi/import/his/`, {params: e}).then(resp => {
                        this.table.data = resp.data.data.data || []
                        this.table.total = resp.data.data.total || 0
                        this.loading = false
                    })
                }, 500)
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>
