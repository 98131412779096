<template>
    <div class="page">
        <el-page-header @back="$router.go(-1)" content="统计报表"/>
    </div>
</template>

<script>
    export default {
        name: 'statistics',
        path: '/main/statistics'
    }
</script>
