<template>
    <div>dashboard</div>
</template>

<script>
    export default {
        name: 'dashboard',
        path: '/main/dashboard'
    }
</script>
