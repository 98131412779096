<template>
    <div>
        <el-table stripe :data="table.data" style="width: 100%" border>
            <el-table-column type="index" align="center" width="50"/>
            <el-table-column align="center"
                             v-for="(v, idx) in table.columns"
                             :prop="v.field"
                             :label="v.title"
                             :width="v.width"
                             :formatter="v.formatter"
                             :filters="v.filters"
                             :key="idx">
                <template slot-scope="scope">
                    <el-input v-model="scope.row[v.field]" v-if="scope.row.isEdit"></el-input>
                    <span style="margin-left: 10px" v-else>{{ scope.row[v.field] }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="150" fixed="right">
                <template slot-scope="scope">
                    <el-button
                        size="small"
                        type="success"
                        icon="el-icon-check"
                        @click="handleSave(scope.row)" v-if="scope.row.isEdit"></el-button>
                    <el-button
                        size="small"
                        type="primary"
                        icon="el-icon-edit"
                        @click="handleEdit(scope.row)" v-else></el-button>
                    <el-button @click="handleDel(scope.$index, scope.row)"
                               size="small"
                               type="danger"
                               icon="el-icon-delete"></el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="text-align: left;margin:10px 0">
            <el-button size="small" type="primary" @click="handleAdd">添加记录</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['id'],
        data() {
            return {
                table: {
                    columns: [
                        {title: '检测日期', field: 'date', width: '120'},
                        {title: '病卡号', field: 'ptnum', width: '150', formatter: null},
                        {title: '样本号', field: 'ybnum', width: '300', formatter: null},
                        {title: '科室编号', field: 'keshi_id', width: '100', formatter: null},
                        {title: '科室名称', field: 'keshi', width: '250'},
                        {title: '诊断', field: 'zhenduan', width: '150'},
                        {title: '淋巴细胞数', field: 'lbxbs', width: '150'},
                        {title: '总T淋巴细胞', field: 'ztlbxb', width: '150'},
                        {title: 'CD8细胞', field: 'bjs_2', width: '120'},
                        {title: 'CD4细胞', field: 'bjs_4', width: '120'},
                        {title: 'NK细胞', field: 'bjs_6', width: '100'},
                        {title: '获取细胞数', field: 'bjs_10', width: '110'},
                        {title: 'B细胞', field: 'zlhsxb', width: '120'},
                        {title: 'CD4CD8', field: 'cd4cd8', width: '120'},
                    ],
                    data: [],
                },
            }
        },
        methods: {
            handleAdd() {
                this.table.data.push({patient_id: this.id, isEdit: true})
            },
            async handleDel(index, row) {
                if (!row.id) {
                    this.getData()
                } else {
                    try {
                        const confirm = await this.$confirm(`确认删除第 ${index + 1} 行的记录？`)
                        if (confirm) {
                            const resp = await this.$http.delete(`/fengshi/linba/${row.id}`).catch(err => err)
                            if (resp.data.code == 200) {
                                this.$message.success(resp.data.data)
                            } else {
                                this.$message.error(resp.data.data)
                            }
                            this.getData()
                        }
                    } catch (error) {
                        console.log(error)
                    }
                }
            },
            handleEdit(row) {
                row.isEdit = true
            },
            async handleSave(row) {
                delete row.isEdit
                let method = row.id ? 'put' : 'post'
                let url = row.id ? `/fengshi/linba/${row.id}` : '/fengshi/linba/'
                let resp = await this.$http[method](url, row)
                if (resp.data.code == 200) {
                    this.$message.success("保存成功")
                    row.isEdit = false;
                    this.getData()
                } else {
                    this.$message.error(resp.data.data)
                }
            },
            async getData() {
                const resp = await this.$http.get(`/fengshi/linba/?patient_id=${this.id}`)
                let list = resp.data.data || []
                list.forEach(e => {
                    e.isEdit = false
                })
                this.table.data = list
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>
