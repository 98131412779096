<template>
    <el-main class="el-main">
        <el-card class="box-card">
            <div slot="header">
                <el-page-header @back="$router.go(-1)" content="修改密码" />
            </div>
            <el-form ref="form" class="form" :model="form" :rules="rules" label-width="100px">
                <el-form-item label="原密码" prop="password">
                    <el-input v-model="form.password" show-password />
                </el-form-item>
                <el-form-item label="新密码" prop="newpassword1">
                    <el-input v-model="form.newpassword1" show-password />
                </el-form-item>
                <el-form-item label="确认密码" prop="newpassword2">
                    <el-input v-model="form.newpassword2" show-password />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">修改密码</el-button>
                    <el-button @click="onCancel">取消</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </el-main>
</template>

<script>
    export default {
        name: 'HelloWorld',
        data() {
            const validatePass2 = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请再次输入新密码'));
                } else if (value !== this.form.newpassword1) {
                    callback(new Error('两次输入密码不一致!'));
                } else {
                    callback();
                }
            };
            return {
                form: {
                    password: '',
                    newpassword1: '',
                    newpassword2: '',
                },
                rules: {
                    password: [
                        {required: true, message: '请输入原密码', trigger: 'blur'},
                        {min: 5, max: 132, message: '长度在 5 到 32 个字符', trigger: 'blur'}
                    ],
                    newpassword1: [
                        {required: true, message: '请输入新密码', trigger: 'blur'},
                        {min: 5, max: 32, message: '长度在 5 到 32 个字符', trigger: 'blur'}
                    ],
                    newpassword2: [
                        {required: true, validator: validatePass2, trigger: 'blur'},
                        {min: 5, max: 32, message: '长度在 5 到 32 个字符', trigger: 'blur'}
                    ]
                }
            }
        },
        methods: {
            onSubmit() {
                const that = this;
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        that.loading = true
                        setTimeout(function () {
                            that.$http
                                .post('/auth/passwd/', that.form)
                                .then(function (res) {
                                    if (res.data.code === 200) {
                                        that.$message({
                                            type: 'success',
                                            message: '修改成功',
                                            showClose: true
                                        })
                                    } else {
                                        that.$message({
                                            type: 'error',
                                            message: res.data.msg,
                                            showClose: true
                                        })
                                    }
                                    that.loading = false
                                })
                                .catch(function (err) {
                                    that.loading = false
                                    console.log(err)
                                })
                        }, 500)
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            onCancel() {
                // this.$refs['form'].resetFields();
                this.$router.go(-1)
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .box-card {
        margin: 30px auto;
        max-width: 600px;
    }

    .form {
        padding-right: 30px;
    }
</style>
