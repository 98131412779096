<template>
    <zk-admin-table title="分配社区" @search="handleSearch" :table="table" :loading="loading">
        <div slot="search">
        </div>
        <el-table stripe :data="table.data" style="width: 100%" v-loading="loading" border height="100%" @row-click="rowClick">
            <el-table-column type="selection" align="center" width="50"/>
            <el-table-column align="center"
                             v-for="(v, idx) in table.columns"
                             :prop="v.field"
                             :label="v.title"
                             :width="v.width"
                             :formatter="v.formatter"
                             :filters="v.filters"
                             :key="idx">
            </el-table-column>
        </el-table>
    </zk-admin-table>
</template>

<script>
    export default {
        name: 'patient',
        path: '/main/patient',
        data() {
            return {
                loading: false,
                params: {
                    page: 1,
                    pageSize: 20,
                    doctor: []
                },
                table: {
                    buttons: [],
                    search: [],
                    columns: [
                        {title: '姓名', field: 'name', width: '200'},
                        {title: '身份证号', field: 'idnum', width: '200', formatter: null},
                        {title: '病卡号', field: 'hnum', width: '200', formatter: null},
                        {title: '联系电话', field: 'phone', width: '200', formatter: null},
                        // {title: '性别', field: 'gender', width: ''},
                        // {title: '年龄', field: 'age', width: ''},
                        {title: '创建时间', field: 'created', width: ''},
                    ],
                    data: [],
                    total: 0,
                    page: 1,
                    pageSize: 20
                },
            }
        },
        methods: {
            handleSearch(e) {
                this.params.page = e.page
                this.params.pageSize = e.pageSize
                this.getData()
            },
            rowClick(row) {
                let path = `/main/patient/view/${row.id}`
                this.$store.commit('setRouterHistory', {label: row.name, path: path})
                this.$router.push({
                    path: path
                })
            },
            async getData(){
                this.loading = true
                setTimeout(function () {
                    this.$http.get(`/fengshi/patient/`, {params: this.params}).then(resp => {
                        this.table.data = resp.data.data.data || []
                        this.table.total = resp.data.data.total || 0
                        this.loading = false
                    })
                }.bind(this), 200)
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>
