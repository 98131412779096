<template>
    <div class="page">
        <el-page-header @back="$router.go(-1)" :content="`患者详情：${patient.name}`"/>
        <div class="view">
            <el-tabs v-model="activeName">
                <el-tab-pane label="患者信息" name="patient">
                    <el-form :model="patient" ref="form" label-width="120px" style="max-width: 400px;margin-top: 30px">
                        <el-form-item label="姓名">
                            <el-input v-model="patient.name"></el-input>
                        </el-form-item>
                        <el-form-item label="身份证号">
                            <el-input v-model="patient.idnum"></el-input>
                        </el-form-item>
                        <el-form-item label="就诊卡号">
                            <el-input v-model="patient.hnum"></el-input>
                        </el-form-item>
                        <el-form-item label="联系电话">
                            <el-input v-model="patient.phone"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="addSubmit">确 定</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane label="OAPS病例" name="oaps">
                    <oaps :id="$route.params.id"/>
                </el-tab-pane>
                <el-tab-pane label="template1" name="template1">
                    <template1 :id="$route.params.id"/>
                </el-tab-pane>
                <el-tab-pane label="淋巴细胞亚群检测" name="linba">
                    <linba :id="$route.params.id"/>
                </el-tab-pane>
                <el-tab-pane label="辅助淋巴细胞亚群检测" name="fuzhulinba">
                    <fuzhulinba :id="$route.params.id"/>
                </el-tab-pane>
                <el-tab-pane label="检查报告" name="lis">
                    <el-form :model="lis_form" :inline="true" style="margin:10px auto;text-align: center">
                        <el-form-item label="日期范围">
                            <el-date-picker
                                v-model="lis_form.daterange"
                                type="daterange"
                                align="right"
                                unlink-panels
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                            >
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="lisSearch">查询</el-button>
                        </el-form-item>
                    </el-form>
                    <el-table stripe :data="lisTable.data" style="width: 100%" border v-loading="lisTable.loading" @row-click="lisReport">
                        <el-table-column type="index" align="center" width="50"/>
                        <el-table-column align="center"
                                         v-for="(v, idx) in lisTable.columns"
                                         :prop="v.field"
                                         :label="v.title"
                                         :width="v.width"
                                         :formatter="v.formatter"
                                         :filters="v.filters"
                                         :key="idx">
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
            </el-tabs>
        </div>
        <el-dialog slot="dialog" title="报告详情" :visible.sync="dialogVisible" width="70%" center>
            <el-table stripe :data="lisDetailTable.data" style="width: 100%" border v-loading="lisDetailTable.loading" @row-click="lisReport">
                <el-table-column type="index" align="center" width="50"/>
                <el-table-column align="center"
                                 v-for="(v, idx) in lisDetailTable.columns"
                                 :prop="v.field"
                                 :label="v.title"
                                 :width="v.width"
                                 :formatter="v.formatter"
                                 :filters="v.filters"
                                 :key="idx">
                </el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                            <el-button @click="dialogVisible = false">关 闭</el-button>
<!--                            <el-button type="primary" @click="addSubmit">确 定</el-button>-->
                        </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        components: {
            'oaps': () => import('./widgets/oaps'),
            'template1': () => import('./widgets/template1'),
            'linba': () => import('./widgets/linba'),
            'fuzhulinba': () => import('./widgets/fuzhulinba'),
        },
        name: 'patient_view',
        path: '/main/patient/view/:id',
        data() {
            let start = new Date(new Date(new Date().toLocaleDateString()).getTime());
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            const end = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1);
            return {
                activeName: 'patient',
                patient: {},
                lis_form: {
                    code: '',
                    daterange: [start.format("yyyy-MM-dd"), end.format("yyyy-MM-dd")],
                },
                lisTable: {
                    loading: false,
                    columns: [
                        {title: '检查日期', field: 'inspectionDate', width: '200'},
                        {title: '项目', field: 'inspectionName', width: '200', formatter: null},
                        {title: '姓名', field: 'patientName', width: '200', formatter: null},
                        {title: '报告医生', field: 'reportDoctorName', width: '200', formatter: null},
                        {title: '诊断', field: 'clinicalDiagnosis', width: ''},
                        {title: '申请科室', field: 'deptName', width: ''},
                    ],
                    data: [],
                },
                lisDetailTable: {
                    loading: false,
                    columns: [
                        {title: '检查日期', field: 'testDate', width: '140', formatter: null},
                        {title: '项目名称', field: 'itemName', width: ''},
                        {title: '结果', field: 'testResult', width: '120', formatter: null},
                        {title: '单位', field: 'unit', width: '100', formatter: null},
                        {title: '参考值', field: 'itemRef', width: '120'},
                    ],
                    data: [],
                },
                dialogVisible: false,
            }
        },
        methods: {
            async lisReport(row){
                this.dialogVisible = true
                this.lisDetailTable.loading = true
                const resp = await this.$http.get(`/fengshi/import/lis/detail/${this.$route.params.id}?code=${row.inspectionId}`)
                this.lisDetailTable.data = resp.data.data
                this.lisDetailTable.loading = false
            },
            async lisSearch() {
                if (this.lis_form.code) {
                    this.lisTable.loading = true
                    const resp = await this.$http.get(`/fengshi/import/lis/${this.$route.params.id}`, {params: this.lis_form})
                    this.lisTable.data = resp.data.data
                    this.lisTable.loading = false
                }
            },
            async addSubmit() {
                const resp = await this.$http.put(`/fengshi/patient/${this.patient.id}`, this.patient)
                if (resp.data.code == 200) {
                    this.$message.success("保存成功")
                } else {
                    this.$message.error(resp.data.data)
                }
            },
            async getData() {
                const resp = await this.$http.get(`/fengshi/patient/${this.$route.params.id}`)
                this.patient = resp.data.data
                this.lis_form.code = this.patient.hnum
                this.lisSearch()
            }
        },
        mounted() {
            // console.log(this.$route.params.id)
            this.getData()
        }
    }
</script>

<style>
    .view {
        margin-top: 20px;
    }
</style>
