import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css' // 响应式
import http from './plugins/http.js'
import './plugins/prototype'
import './example/router'
import components from './components'
Vue.use(components)

Vue.use(ElementUI);
// Vue.use(ElementUI, { size: 'small', zIndex: 3000 });

Vue.prototype.$http = http

/* 或写为
// import { Button, Select } from 'element-ui';
Vue.component(Button.name, Button);
Vue.component(Select.name, Select);
 * Vue.use(Button)
 * Vue.use(Select)
 */
