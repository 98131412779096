import Vue from 'vue'
import App from './common/App.vue'
import router from './common/example/router'
import store from './common/example/store'
import http from './common/plugins/http.js'
import './common/'

Vue.config.productionTip = false

Vue.prototype.$title = '风湿数据管理'
Vue.prototype.$http = http

new Vue({
    render: h => h(App),
    router,
    store,
}).$mount('#app')
